export const baseUrl =
  window.location.origin !== "http://localhost:3000"
    ? window.location.origin + process.env.REACT_APP_API_URL
    : process.env.REACT_APP_DEV_URL;

export const dynamicSiteSelectorVariables = [
  {
    domain: "aratiatia.mercury.keepsafe.work",
    induction_id: "5",
    induction_name: "Aratiatia",
  },
  {
    domain: "ngatamariki.mercury.keepsafe.work",
    induction_id: "4",
    induction_name: "Ngatamariki",
  },
  {
    domain: "staging.mercury.keepsafe.work",
    induction_id: "4",
    induction_name: "Ngatamariki",
  },
  { domain: "localhost", induction_id: "4", induction_name: "Ngatamariki" },
];
